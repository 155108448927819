<template>
  <ul>
    <div :key="item.header || item.title" v-for="item in items">
      <!-- Renderiza o item "Administração" -->
      <component :is="resolveNavItemComponent(item)" :item="item" v-if="item.title === 'Administração' && accesses.is_admin" />

      <!-- Renderiza <hr> após a opção "Administração" -->
      <template v-if="item.title === 'Administração'">
        <hr v-show="accesses.is_admin" class="menu-separator" />
        <p class="text-center" style="font-weight: 800">Área de Trabalho</p>
        <div>
          <component :is="resolveNavItemComponent(dashboardItem)" :item="dashboardItem" v-if="dashboardItem && accesses" />
        </div>
       
      </template>

      <!-- Renderiza outros itens com base nos acessos -->
      <component
        :is="resolveNavItemComponent(item)"
        :item="item"
        v-if="
          item.title !== 'Administração' &&
          item.title !== 'Dashboard' &&
          ((item.title === '' && accesses.is_manager) || (item.title === 'Financeiro' && accesses.is_financer))
        "
      />
    </div>
  </ul>
</template>

<script>
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@core/layouts/utils';
import { provide, ref } from '@vue/composition-api';
import VerticalNavMenuHeader from '../vertical-nav-menu-header';
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue';
import VerticalNavMenuGroup from '../vertical-nav-menu-group/VerticalNavMenuGroup.vue';

export default {
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup
  },
  data() {
    return {
      accesses: {
        is_admin: false,
        is_manager: false,
        is_financer: false
      }
    };
  },
  computed: {
    currentWorkspace() {
      return this.$store.getters.currentWorkspace;
    },
    dashboardItem() {
      return this.items.find(item => item.title === 'Dashboard');
    }
  },
  props: {
    items: {
      type: Array,
      required: true
    }
  },

  methods: {
    checkAuth() {
      const _user = this.$store.getters.user.accesses.find(a => a.workspace_id == this.currentWorkspace.id);
      console.log('user',_user);
      this.accesses.is_admin = _user.is_admin ;
      this.accesses.is_manager = _user.is_manager;
      this.accesses.is_financer = _user.is_transaction_manager;

      console.log('check auth', this.accesses);
    }
  },
  beforeMount: function () {
    console.log('ITEMS', this.items);
    this.checkAuth();
  },

  setup() {
    provide('openGroups', ref([]));

    return {
      resolveNavItemComponent
    };
  }
};
</script>

<style scoped>
.menu-separator {
  margin-top: 40px;
}
</style>
